import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Ensure you import the calendar styles
import Navbar from '../components/navbar'; // Adjust the import path if necessary
import './event.css';
import Article from '../components/article'

const Event = (props) => {
  const [date, setDate] = useState(new Date());
  const [selectedImage, setSelectedImage] = useState(1);
  const [showForm, setShowForm] = useState(false);

  // List of images
  const imageMap = {
    1: '/SectionImages/e1.png',
    2: '/SectionImages/e2.png',
    3: '/SectionImages/e3.png',
    4: '/SectionImages/e4.png',
    5: '/SectionImages/e5.png'
  };

  // Function to handle date change and update image
  const handleDateChange = (newDate) => {
    setDate(newDate);
    const randomImageIndex = Math.floor(Math.random() * 5) + 1;
    setSelectedImage(randomImageIndex);
    setShowForm(true); // Show the form when a date is clicked
  };

  // Generate random location
  const getRandomLocation = () => {
    const locations = ['Park', 'Stadium', 'Gym', 'Community Center', 'School Field'];
    return locations[Math.floor(Math.random() * locations.length)];
  };

  // Generate random time
  const getRandomTime = () => {
    const hours = Math.floor(Math.random() * 12) + 1;
    const minutes = Math.floor(Math.random() * 60);
    const period = Math.random() > 0.5 ? 'AM' : 'PM';
    return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
  };

  // Format the selected date
  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

  return (
    <div className="home-container1">
      <Navbar rootClassName="navbarroot-class-name" />
      <header className="home-header10">
        <h1 className="home-heading10">Discover Sports Events.</h1>
        <span className="home-caption1">
          Discover and participate in sports events happening nearby for the next two weeks.
        </span>
      </header>
      <br />
      <div>
        <Calendar
          className='calendar'
          onChange={handleDateChange}
          value={date}
        />
      </div>
      <p className='home-caption1'>Event(s) on {formattedDate}</p>
      <div className="home-image17">
            <img
              alt="image"
              src={imageMap[selectedImage]}
              className="img-evnt"
            />
        {/* <div> */}
      </div>
      {showForm && (
        <div className="event-form-container">
          <div className="event-form">
            <p><strong>Location:</strong> {getRandomLocation()}</p>
            <p><strong>Time:</strong> {getRandomTime()}</p>
            <br />
            <form>
              <label>
                Name:
                <br />
                <input type="text" name="name" />
              </label>
              <br />
              <label>
                Number of Players:
                <br />
                <input type="number" name="players" />
              </label>
              <br />
              <label>
                Team Name:
                <br />
                <input type="text" name="team" />
              </label>
              <br />
              <button type="submit">Register</button>
              <br />
              <button type="button" onClick={() => setShowForm(false)}>Close</button>
            </form>
          </div>
        </div>
      )}
      <br/>
      <main className="home-cards3">
  <Article
    header="Event Booking"
    description="Easily book your spot in upcoming sports events through our platform, ensuring you never miss out on any exciting opportunities to stay active and engaged."
    rootClassName="articleroot-class-name"
  ></Article>
  <Article
    header="Community Joining"
    description="Connect with like-minded individuals by joining various sports communities on P L I V E. Share your passion for sports, exchange tips, and engage with fellow enthusiasts."
    rootClassName="event-column"
  ></Article>
  <Article
    header="Marketplace"
    description="Browse through a wide selection of sports accessories available for purchase or sale on our platform. Find the gear you need or sell your unused equipment to fellow sports lovers."
    rootClassName="event-column"
  ></Article>
</main>
<br/>
<br/>
<br/>
<br/>
<br/>
<section className="home-section28">
        <main className="home-content6">
          <header className="home-header23">
            <h2 className="home-heading27 section-heading">
              Let's revolutize the way we play and connect!
            </h2>
            <div className="home-buttons3">
              <div className="home-ios button">
                <img
                  alt="image"
                  src="/Icons/apple-200w.png"
                  className="home-icon1"
                />
                <span className="home-text50">Download for iOS</span>
              </div>
              <div className="home-android button">
                <img
                  alt="image"
                  src="/Icons/android-200h.png"
                  className="home-icon2"
                />
                <span className="home-text51">Download for Android</span>
              </div>
            </div>
          </header>
          <img
            alt="image"
            src="/SectionImages/Page 1 (1).jpeg"
            className="home-image25"
          />
        </main>
      </section>

<footer className="home-footer">
        <div className="home-content7">
          <main className="home-main-content">
            <div className="home-content8">
              <header className="home-main5">
                <div className="home-header24">
                  <span className="home-text52">
                    P L I V E
                  </span>
                </div>
                <div className="home-socials">
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link1"
                  >
                    <img
                      alt="image"
                      src="/Icons/linkedin-200h.png"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link2"
                  >
                    <img
                      alt="image"
                      src="/Icons/instagram-200h.png"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link3"
                  >
                    <img
                      alt="image"
                      src="/Icons/twitter-200h.png"
                      className="social"
                    />
                  </a>
                </div>
              </header>
              <header className="home-categories">
                <div className="home-category1">
                  <div className="home-header25">
                    <span className="footer-header">Solutions</span>
                  </div>
                  <div className="home-links1">
                    <span className="footer-link">Responsive Web Design</span>
                    <span className="footer-link">Responsive Prototypes</span>
                    <span className="footer-link">Design to Code</span>
                    <span className="footer-link">Static Website Builder</span>
                    <span className="footer-link">
                      Static Website Generator
                    </span>
                  </div>
                </div>
                <div className="home-category2">
                  <div className="home-header26">
                    <span className="footer-header">Company</span>
                  </div>
                  <div className="home-links2">
                    <span className="footer-link">About</span>
                    <span className="footer-link">Team</span>
                    <span className="footer-link">News</span>
                    <span className="footer-link">Partners</span>
                    <span className="footer-link">Careers</span>
                    <span className="footer-link">Press &amp; Media</span>
                  </div>
                </div>
              </header>
            </div>
            <section className="home-copyright1">
              <span className="home-text66">
                © 2024 NYC. Credits will be given.
              </span>
            </section>
          </main>
          <main className="home-subscribe">
            <main className="home-main6">
              <h1 className="home-heading28">Sign up for P L I V E</h1>
              <div className="home-input-field">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="home-textinput input"
                />
                <div className="home-buy4 button">
                  <span className="home-text67">-&gt;</span>
                  <span className="home-text68">
                    <span>Subscribe now</span>
                    <br></br>
                  </span>
                </div>
              </div>
            </main>
            <h1 className="home-notice">
              By signing up for P L I V E you will receive a mails about the exciting events, gears and community updates.
            </h1>
          </main>
          <section className="home-copyright2">
            <span className="home-text71">
              © 2024 NYC. Credits will be given.
            </span>
          </section>
        </div>
      </footer>

    </div>
  );
};

export default Event;
